import {ScrollingModule} from '@angular/cdk/scrolling';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {registerLocaleData} from '@angular/common';
import {HttpClientXsrfModule} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {EffectsModule} from '@ngrx/effects';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {NgxMatomoRouterModule} from '@ngx-matomo/router';
import {MatomoConsentMode, NgxMatomoTrackerModule} from '@ngx-matomo/tracker';
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {NestedEllipsisModule} from 'ngx-nested-ellipsis';
import {Observable, of} from 'rxjs';
import {environment} from '../environments/environment';
import {AccountActivationComponent} from './account-activation/account-activation.component';
import {AppMaterialModule} from './app-material.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AppEffects} from './app.effects';
import {reducer} from './app.reducer';
import {AssessmentApiModule} from '@backend-api/assessment-api-module';
import {ElearningApiModule} from '@backend-api/elearning-api-module';
import {GuestApiModule} from '@backend-api/guest-api-module';
import {PublicUserApiModule} from '@backend-api/public-user-api-module';
import {RecommendationApiModule} from '@backend-api/recommendation-api-module';
import {UserApiModule} from '@backend-api/user-api-module';
import {CookieBannerComponent} from './cookie-banner/cookie-banner.component';
import {CookieSettingsDialogComponent} from './cookie-settings-dialog/cookie-settings-dialog.component';
import {WINDOW} from './core/window';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AssessmentModule} from './features/assessment/assessment.module';
import {ELearningModule} from './features/e-learning/e-learning.module';
import {OrderModule} from './features/order/order.module';
import {RecommendationModule} from './features/recommendation/recommendation.module';
import {UserModule} from './features/user/user.module';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {IconsModule} from './icons.module';
import {LoginPageComponent} from './login-page/login-page.component';
import {ReportMissingTranslationHandler} from './missing-translation-handler';
import {NotFoundComponent} from './not-found/not-found.component';
import {OnboardingModule} from './onboarding/onboarding.module';
import {PipesModule} from './pipes/pipes.module';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {CustomURLSerializer} from './shared/custom-url-serializer';
import {SharedModule} from './shared/shared.module';
import {SignupSuccessDialogComponent} from './signup-success-dialog/signup-success-dialog.component';
import {SignupFormCampaignComponent} from './signup/signup-form-campaign/signup-form-campaign.component';
import {SignupFormDefaultComponent} from './signup/signup-form-default/signup-form-default.component';
import {SignupComponent} from './signup/signup.component';
import {UiComponentsModule} from './ui-components/ui-components.module';
import {configurationHelper} from 'src/configuration/configuration';
import {createErrorHandlerFactory} from '@appsignal/angular';
import {appsignal} from './appsignal';

registerLocaleData(localeDe);

export function getTranslateLoader(): {getTranslation: (lang: string) => Observable<unknown>} {
  // eslint-disable-next-line
    const de = require('../assets/i18n/de.json') as any;
  // eslint-disable-next-line
    const en = require('../assets/i18n/en.json') as any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translations = {de, en} as any;

  return {
    getTranslation: (lang: string) => of(translations[lang]),
  };
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NotFoundComponent,
    LoginPageComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SignupComponent,
    SignupFormDefaultComponent,
    SignupFormCampaignComponent,
    AccountActivationComponent,
    SignupSuccessDialogComponent,
    CookieBannerComponent,
    CookieSettingsDialogComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    LoadingBarHttpClientModule,
    HttpClientXsrfModule.withOptions({headerName: 'X-CSRF-Token'}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: getTranslateLoader,
      },
      // TODO: compiler says this isn't valid opposed to the docs?!
      // defaultLanguage: 'de',
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: ReportMissingTranslationHandler},
      useDefaultLang: false,
    }),
    NestedEllipsisModule,
    ScrollingModule,

    AppMaterialModule,
    IconsModule,
    UiComponentsModule,

    ElearningApiModule.forRoot('/elearning_api'),
    RecommendationApiModule.forRoot('/recommendation_api'),
    GuestApiModule.forRoot('/guest_api'),
    AssessmentApiModule.forRoot('/assessment_api'),
    UserApiModule.forRoot('/user_api'),
    PublicUserApiModule.forRoot('/public_user_api'),

    StoreModule.forRoot(
      {app: reducer},
      {
        /*metaReducers*/
      }
    ),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    EffectsModule.forRoot([AppEffects]),
    StoreRouterConnectingModule.forRoot({serializer: CustomURLSerializer}),

    PipesModule,
    ELearningModule,
    AssessmentModule,
    RecommendationModule,
    UserModule,
    AppRoutingModule,
    SharedModule,
    OrderModule,
    OnboardingModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    NgxMatomoTrackerModule.forRoot({
      // do not load for dev-envs!
      disabled: !environment.production,
      // pull params from environment, every non-prod-system uses a different APP_ID
      trackerUrl: configurationHelper.needConfig('MATOMO_TRACKING_URL'),
      siteId: configurationHelper.needConfig('MATOMO_APP_ID'),
      // deactivates tracking and cookies initially, so we can use opt-in
      requireConsent: MatomoConsentMode.TRACKING,
    }),
    NgxMatomoRouterModule,
  ],
  providers: [
    CookieService,
    {provide: WINDOW, useValue: window},
    {
      provide: ErrorHandler,
      useValue: createErrorHandlerFactory(appsignal),
    },
    {provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}},
    {provide: DeviceDetectorService, useClass: DeviceDetectorService},
    {provide: LOCALE_ID, useValue: 'de-DE'},
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
