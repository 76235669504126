<div class="filter">
  <div class="container py-4" *ngIf="isFilterOpened" [@expandOnEnter] [@collapseOnLeave]>
    <div class="row align-items-baseline">
      <ng-container *ngIf="topics$ | async as topics">
        <p class="headline text-center col-12" translate>FILTER.HEADER</p>
        <mat-chip-listbox class="col-12 mt-4" selectable multiple>
          <mat-chip-option
            color="primary"
            #chip
            *ngFor="let topic of topics"
            (click)="toggleFilterSelection(topic.id)"
            [selected]="topic.selected">
            {{getTopicTitle(topic)}}
            <mat-icon *ngIf="chip.selected" svgIcon="close"></mat-icon>
          </mat-chip-option>
        </mat-chip-listbox>
      </ng-container>
    </div>
    <div class="row mt-4 justify-content-end button-row">
      <button class="col-auto" mat-button color="primary" (click)="selectAllTopics()" disableRipple>
        <span translate>FILTER.SELECT_ALL</span>
      </button>
      <button class="ms-2 col-auto" mat-button color="primary" (click)="deselectAllTopics()" disableRipple>
        <span translate>FILTER.SELECT_NONE</span>
      </button>
    </div>
    <div class="row mt-4">
      <label class="headline text-center" id="radio-label-language-select">{{'FILTER.LANGUAGE.HEADER' | translate}}</label>
      <mat-radio-group aria-labelledby="radio-label-language-select" class="d-flex justify-content-center" [(ngModel)]="selectedLanguage">
        <mat-radio-button *ngFor="let lang of languages" class="mx-2" [value]="lang" (change)="selectLanguage(lang)" color="primary">
          <span translate>FILTER.LANGUAGE.{{lang | uppercase}}</span>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>
<div class="ribbon d-flex justify-content-end">
  <button class="toggle-button" mat-flat-button color="accent" (click)="toggleFilter()">
    <ng-container *ngIf="isFilterOpened">
      <span translate>FILTER.CLOSE</span>
      <i-feather name="chevron-up"></i-feather>
    </ng-container>
    <ng-container *ngIf="!isFilterOpened">
      <span translate>FILTER.OPEN</span>
      <i-feather name="chevron-down"></i-feather>
    </ng-container>
  </button>
</div>
