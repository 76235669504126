import {Component, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {getUserHomePath} from '../app.selectors';
import {loadUser} from '../features/user/user.actions';
import {UserSlice} from '../features/user/user.reducer';
import {selectUser} from '../features/user/user.selectors';
import {resetPassword, resetPasswordError} from '../shared/shared.actions';
import {getTranslateStringFromMappableError} from '../shared/shared.effects';
import {SharedSlice} from '../shared/shared.reducer';
import {selectRouteData, selectTokenParam} from '../shared/shared.selectors';

export const passwordsMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.get('password');
  const passwordConfirm = control.get('passwordConfirm');

  return password && passwordConfirm && password.value !== passwordConfirm.value ? {passwordsMatch: true} : null;
};

export interface MappedBeError {
  // remap BE-Type for simpler usage in template
  // TODO: might change based on actual BE-impl
  message: string;
  attribute: string;
  type: string;
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm = new UntypedFormGroup(
    {
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.pattern(/\d/),
        Validators.pattern(/[a-zA-Z]/),
      ]),
      passwordConfirm: new UntypedFormControl('', []),
    },
    {
      validators: passwordsMatchValidator,
    }
  );

  resetToken$ = this._store.select(selectTokenParam);
  readonly user$ = this._store.select(selectUser);
  private readonly _subscriptions = new Subscription();
  private readonly _routeData$ = this._store.select(selectRouteData);
  isWelcome = false;
  error?: MappedBeError;

  constructor(
    private readonly _store: Store<SharedSlice & UserSlice>,
    private readonly _router: Router,
    private readonly _actions$: Actions
  ) {}

  ngOnInit(): void {
    this._store.dispatch(loadUser());

    this._subscriptions.add(
      this._routeData$.subscribe((data) => {
        // Component is used for two purposes
        this.isWelcome = !!data?.welcome;
      })
    );

    // special use-case: some users bookmark the welcome-link or permanently use the welcome-mail to enter troodi
    // this leads to puzzled users, as they will get error-messages about the wrong key
    // So: if a user is already logged in, directly navigate to the home-path
    this.user$
      .pipe(
        filter((user) => !!user),
        take(1)
      )
      .subscribe((user) => {
        if (!!user) {
          this._router.navigateByUrl(getUserHomePath(user));
        }
      });

    this._actions$.pipe(ofType(resetPasswordError), take(1)).subscribe((action) => {
      this.error = {
        message: getTranslateStringFromMappableError(action.error),
        attribute: action.error.attribute,
        type: action.error.type,
      };
    });
  }

  // Show different string based on whether the component is used for welcome or password-reset
  getStringKey(key: string) {
    return `${this.isWelcome ? 'SET_PASSWORD' : 'RESET_PASSWORD'}.${key}`;
  }

  resetPassword(token: string) {
    const password = this.resetPasswordForm.value['password'] as string;
    const passwordConfirm = this.resetPasswordForm.value['passwordConfirm'] as string;
    this._store.dispatch(
      resetPassword({
        reset_password_token: token,
        password,
        password_confirmation: passwordConfirm,
      })
    );
  }

  get password() {
    return this.resetPasswordForm.get('password');
  }
  get passwordConfirm() {
    return this.resetPasswordForm.get('passwordConfirm');
  }
}
