import {Component, OnInit} from '@angular/core';
import {MatSliderChange} from '@angular/material/slider';
import {Store} from '@ngrx/store';
import {loadSkillProfiles, loadSkills, setSkillValue} from '../recommendation.actions';
import {RecommendationSlice} from '../recommendation.reducer';
import {selectAllSkillViewModels} from '../recommendation.selectors';

@Component({
  selector: 'app-skill',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.scss'],
})
export class SkillComponent implements OnInit {
  readonly skills$ = this.store.select(selectAllSkillViewModels);

  constructor(private readonly store: Store<RecommendationSlice>) {}

  ngOnInit() {
    this.store.dispatch(loadSkills());
    this.store.dispatch(loadSkillProfiles());
  }

  onChange(change: number, tid: string) {
    if (change) {
      this.store.dispatch(setSkillValue({id: tid, value: change}));
    }
  }

  getPosition(value: number) {
    const division = value / 10;
    return `calc(${division * 100}% - 6px)`;
  }

  isOverlaying(value: number, compareValue: number) {
    return value === compareValue;
  }
}
