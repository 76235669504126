import {Location} from '@angular/common';
import {ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {fadeInAnimation} from 'src/app/animations';
import {Dictionary} from 'src/app/shared/data.service';
import {openOnboarding} from '../../../onboarding/store/actions';
import {invalidateTrainings} from '../../e-learning/e-learning.actions';
import {setUserData} from '../user.actions';
import {Language} from '../user.data.service';
import {UserState} from '../user.reducer';
import {selectUser} from '../user.selectors';

export const languageMapping: Dictionary<string, Language> = {
  de: 'Deutsch',
  en: 'English',
};

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  @HostBinding('@fadeInAnimation') loadAnimation = true;

  private readonly _subscriptions = new Subscription();
  readonly user$ = this._store.select(selectUser);
  readonly languageMapping = languageMapping;
  userLang?: Language;

  profileForm = new UntypedFormGroup({
    firstName: new UntypedFormControl('', Validators.required),
    lastName: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
    language: new UntypedFormControl(''),
  });

  constructor(private readonly _store: Store<UserState>, private readonly _location: Location) {}

  ngOnInit(): void {
    this._subscriptions.add(
      this.user$.subscribe((user) => {
        if (user) {
          this.userLang = user.language;
          this.profileForm.patchValue({
            firstName: user.first_name ?? '',
            lastName: user.last_name ?? '',
            language: user.language ?? 'de',
          });
          // we want to mark fields as invalid directly after loading
          this.profileForm.markAllAsTouched();
        }
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  setUserData() {
    const firstName = this.profileForm.value['firstName'] as string;
    const lastName = this.profileForm.value['lastName'] as string;
    const language = this.profileForm.value['language'] as Language;
    // if the language was changed, training have to be reloaded
    // TODO: when message-stream is implemented and locale_ids are used, reload the page!
    const langChanged = this.userLang !== language;
    if (langChanged) this._store.dispatch(invalidateTrainings());
    this._store.dispatch(setUserData({firstName, lastName, language}));
    this._location.back();
  }

  cancel() {
    this._location.back();
  }

  get firstName() {
    return this.profileForm.get('firstName');
  }
  get lastName() {
    return this.profileForm.get('lastName');
  }
  get language() {
    return this.profileForm.get('language');
  }

  openOnboarding(): void {
    this._store.dispatch(openOnboarding());
  }
}
