import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AccountActivationComponent} from './account-activation/account-activation.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {assessmentRoutesProtected, assessmentRoutesUnprotected} from './features/assessment/assessment.routes';
import {elearningRoutesProtected} from './features/e-learning/e-learning.routes';
import {orderRoutesUnprotected} from './features/order/order.routes';
import {recommendationRoutesProtected} from './features/recommendation/recommendation.routes';
import {userRoutesProtected} from './features/user/user.routes';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {LoggedInGuard} from './logged-in.guard';
import {LoginPageComponent} from './login-page/login-page.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {RedirectLoggedInUsersGuard} from './redirect-logged-in-users.guard';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {SignupSuccessDialogComponent} from './signup-success-dialog/signup-success-dialog.component';
import {SignupComponent} from './signup/signup.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [LoggedInGuard],
    canActivateChild: [LoggedInGuard],
    children: [
      {path: 'dashboard', component: DashboardComponent},
      ...assessmentRoutesProtected,
      ...elearningRoutesProtected,
      ...recommendationRoutesProtected,
      ...userRoutesProtected,
    ],
  },
  {
    path: '',
    children: [
      {
        path: '',
        canActivate: [RedirectLoggedInUsersGuard],
        children: [
          {path: 'login', component: LoginPageComponent},
          {path: 'welcome', component: ResetPasswordComponent, data: {welcome: true}},
          {path: 'signup', component: SignupComponent},
          {path: 'confirm', component: AccountActivationComponent},
          {path: 'signup-success', component: SignupSuccessDialogComponent},
        ],
      },
      ...assessmentRoutesUnprotected,
      ...orderRoutesUnprotected,
      {path: 'forgot-password', component: ForgotPasswordComponent},
      {path: 'reset-password', component: ResetPasswordComponent},
      {path: '**', component: NotFoundComponent},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {initialNavigation: 'disabled'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
