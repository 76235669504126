import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {validateEmail} from 'src/app/forgot-password/forgot-password.component';
import {passwordsMatchValidator} from 'src/app/reset-password/reset-password.component';
import {SignupFormData, validatePassword} from '../signup.component';

@Component({
  selector: 'app-signup-form-campaign',
  templateUrl: './signup-form-campaign.component.html',
  styleUrls: ['./signup-form-campaign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignupFormCampaignComponent {
  @Output() readonly submitSignup = new EventEmitter<{data: SignupFormData}>();

  signupFormCampaign = new UntypedFormGroup(
    {
      firstName: new UntypedFormControl('', Validators.required),
      lastName: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', [Validators.required, validateEmail]),
      password: new UntypedFormControl('', validatePassword),
      passwordConfirmation: new UntypedFormControl('', []),
      // honeypots
      hCheckbox: new UntypedFormControl(false),
      hInput: new UntypedFormControl(''),
    },
    {
      validators: passwordsMatchValidator,
    }
  );

  submit() {
    this.submitSignup.emit({
      data: this.signupFormCampaign.value,
    });
  }

  // getters needed for the validators
  get firstName() {
    return this.signupFormCampaign.get('firstName');
  }
  get lastName() {
    return this.signupFormCampaign.get('lastName');
  }
  get email() {
    return this.signupFormCampaign.get('email');
  }
  get password() {
    return this.signupFormCampaign.get('password');
  }
  get passwordConfirmation() {
    return this.signupFormCampaign.get('passwordConfirmation');
  }
  get hCheckbox() {
    return this.signupFormCampaign.get('hCheckbox');
  }
  get hInput() {
    return this.signupFormCampaign.get('hInput');
  }
}
